export default {
    published: "Publicadas",
    in_revision: "En Revisión",
    unpublished: "No Publicadas",
    rejected: "Rechazadas",
    requiredEmail: 'Debe completar el campo Email',
    requiredPassword: 'Debe completar el campo Password',
    actions: {
        edit: 'Editar',
        copy: 'Copiar',
        add: 'Agregar',
        cancel: 'Cancelar',
        accept: 'Aceptar',
        delete: 'Eliminar',
        send: 'Enviar',
        close: 'Cerrar',
        enable: 'Habilitar',
        disable: 'Deshabilitar',
        changeTarget: 'Cambiar Target',
        understood: 'Entendido',
        editAR: 'Editar fondo',
        changeAlias: 'Cambiar alias',
        changeName: 'Cambiar nombre',
        changeLoading: 'Cambiar Loading',
        converToAd: 'Convertir a anuncio',
        converToNormal: 'Deshacer modo anuncio'
    },
    common: {
        labels: {
            email: 'Email',
            spanish: 'Español',
            english: 'Inglés',
            type: 'Tipo',
            status: 'Estado',
            actions: 'Acciones',
            active: 'Activo',
            inactive: 'Inactivo',
            nameSurname: 'Nombre y Apellido',
            world: 'Mundo',
            plane: 'Plana',
            imageTracking: 'Image Tracking',
            worldTracking: 'World Tracking',
            immersive: 'Web 360',
            360: '360',
            face: 'Face',
            phone: 'Teléfono',
            country: 'País',
            name: 'Nombre',
            password: 'Contraseña',
            surname: 'Apellido',
            company: 'Compañía',
            project: 'Proyecto',
            user: 'Usuario',
            experience: 'Experiencia'
        },
        msg: {
            doYouContinue: '¿Desea continuar?',
            success: 'Éxito'
        },
        alerts: {
            title: 'Alerta'
        },
    },
    header: {
        placeholder: "Buscar",
        user: 'Usuario',
        profile: 'Perfil',
        logout: 'Salir',
        search: 'Comienza a escribir para buscar entre tus experiencias',
        menu: {
            profile: 'Perfil',
            subscription: 'Suscripción',
            toPro: 'ACTUALIZA A PRO',
            logout: 'Cerrar sesión',
            paymentMethod: 'Método de pago',
            billingInfo: 'Información de facturación.',
            billings: 'Facturas',
            companies: 'Compañías',
            users: 'Usuarios',
            published: 'Publicaciones',
            sdk: 'SDK'
        }
    },
    sidebar: {
        action: 'Nueva experiencia',
        menu: {
            campaign: 'Proyectos',
            recent: 'Recientes',
            published: 'Estatus',
            reports: 'Reportes',
            reportsV2: 'Reportes V2',
            webComponents: 'Overlays',
            title: 'Editar pantalla de carga ',
            controlPanel: 'Panel de control'
        },
        footer: {
            title: 'Tu prueba gratuita finalizará en ',
            time: 'días',
            action: 'Actualizar a Pro'
        }
    },
    customLoaderSidebar: {
        menu: {
            title: 'Editar pantalla de carga',
            subTitle: 'Aquí podras editar la pantalla de carga de tu experiencia. Recuerda que esta edición aplica a todas las experiencias de un mismo proyecto',
            header: 'Imagen header',
            textColor: 'Color de textos',
            loadingBar: 'Barra de carga',
            footer: 'Pie de pagina',
            background: 'Fondo',
            grid: 'Grilla',
            gradiant: 'Gradiante',
            language: 'Idioma',
            spanish: 'Español',
            english: 'Inglés'
        }
    },
    customLoaderLayout: {
        title: 'Editar pantalla de carga',
        error: 'Error',
        back: 'Volver a proyectos',
        apply: 'Aplicar cambios',
        discard: 'Volver a default',
        applyChanges: 'Cambios aplicados con éxito',
        errorChanges: 'Ha ocurrido un error',
        discardMessage: 'Se borrará toda la personalización ¿Estás seguro?',
        formatImage: 'Sólo se pueden cargar imágenes con formato svg',
        yes:"Si"
    },

    editAr:{
        error: 'Error',
        formatImage: 'Sólo se pueden cargar imágenes con formato .jpg o .jpeg',
        ok: "OK"
    },

    dragAndDropFile: {
        textHeader: 'Agrega o arrastra la imagen que quieras',
        textBackground: 'Agrega o arrastra el fondo que quieras',
        format: 'Usa formato .svg',
        format2: 'Usa formato .jpg o .jpeg',
        text: 'Selecciona una imagen',
        quit: 'Eliminar imagen',
        add: 'Agregar',
        inputSelect: 'Selecciona una opción'
    },
    
    experienceNewFlow: {
        invalidSize: 'El target debe de pesar hasta 5 MB',
        internalError: 'Error interno, intente mas tarde',
        title: 'Elige un tipo de experiencia',
        imageTracking: 'Image Tracking',
        imageTrackingDescription: 'En este tipo de experiencia podrás crear contenidos interactivos en realidad aumentada sobre una imagen física (target), por ejemplo: la etiqueta de un producto, un afiche, un mural, etc.',
        worldTracking: 'World Tracking',
        worldTrackingDescription: 'Al crear una experiencia “World Tracking” podrás incluir contenidos 2D y 3D en entornos reales, posicionándolos sobre superficies planas del mundo real.',
        worldTrackingSubtitle: 'Las experiencias interactivas creadas a través de Studio pueden ser visualizadas de 2 formas: vía app (CamOn) o por medio de un navegador web.',
        worldTrackingApp: 'App',
        worldTrackingWeb: 'Web',
        worldTrackingAppDescription: 'CamOn es la aplicación que transforma el dispositivo móvil en un escáner, permitiendo descubrir experiencias interactivas sobre soportes impresos.',
        worldTrackingWebDescription: 'Las experiencias interactivas se visualizan a través del navegador web de un dispositivo móvil, accediendo a través de una URL o escaneando un código QR.',
        markerBased: 'Con target',
        markerLess: 'Sin target',
        markerBasedDescription: 'Para acceder a la experiencia interactiva, el usuario tiene que escanear una imagen a través de la aplicación CamOn.',
        markerLessDescription: 'Si no se define target, el usuario accede a la experiencia escaneando un código QR con la cámara del dispositivo móvil, o a través de un link web.',
        subtitleWorldTracking: 'Define la forma de acceso a estas experiencias',
        prox: 'Proximamente',
        app: 'App',
        web: 'Web',
        immersiveAppDescription: 'CamOn es la aplicación que transforma el dispositivo móvil en un escáner, permitiendo descubrir experiencias interactivas sobre soportes impresos.',
        immersiveWebDescription: 'Las experiencias interactivas se visualizan a través del navegador web de un dispositivo móvil, accediendo a través de una URL o escaneando un código QR.',
        immersive: 'Web 360',
        subtitleImmersive: 'Las experiencias interactivas creadas a través de Studio pueden ser visualizadas de 2 formas: vía app (CamOn) o por medio de un navegador web.',
        subtitleAppImmersive: 'Define la forma de acceso a estas experiencias',
        immersiveDescription: 'Las experiencias “Web 360” sitúan al usuario dentro de un contexto 360°, permitiéndoles descubrir contenidos interactivos a su alrededor. ',
        faceTracking: 'Face Tracking',
        new: 'Nuevo',
        nameOfExperience: 'Nombre de la experiencia',
        nameOfCollection: 'Nombre del proyecto',
        nameOfCampaign: 'Nombre de la campaña',
        addTarget: 'Agrega un target',
        createExperience: 'Crear experiencia',
        offlineExperience: 'Experiencia Offline',
        offlineTargetType: 'Tipo de target',
        forAnotherUser: 'Para otro usuario',
        offlineName: 'Nombre offline',
        advertisement:'Anuncio',
    },
    studioNewFlow: {
        internalError: 'Error interno, intente mas tarde',
        labelFromDragComponent: "Agregar Modelos",
        infoImage: 'Arrastra un modelo en el cuadro para poder agregarlo.',
        interactiveExperince: "Web 360",
        new: "Nuevo",
        title: "Elige un tipo de experiencia",
        interactiveExperinceDescription: "Las experiencias “Web 360” sitúan al usuario dentro de un contexto 360°, permitiéndoles descubrir contenidos interactivos a su alrededor.",
        model3d: "Web AR",
        model3dDescription: "Al crear una experiencia ModelViewer, estarás generando un visualizador de objetos 3D, para posicionarlos sobre superficies planas del mundo real proponiendo probadores virtuales.",
        addModelGLB: "Agregar GLB",
        addModelUSDZ: "Agregar USDZ",
        addOrDragModel: 'Arrastra los modelos 3D',
        format: "Se debe agregar 1ero un modelo formato .GLB y luego agregar un modelo formato USDZ.",
        changeModel: {
            invalidFile: 'Los siguientes archivos fueron excluidos por un inconveniente en la carga: ',
            validFormat: 'Formato de archivo inválido',
            validEmptyTarget: 'Es necesario agregar un modelo',
        },
        newExp: {
            formatsModels: 'Usa formato .glb',
            formatModels2: 'Usa formato .usdz',
        },
        uploadsModels: 'Subir modelos 3D',
        maxFilesError: 'El límite de archivos para subir en simultáneo es de 1. Por favor inténtalo de nuevo.',
        nameError: 'Ya existe una experiencia con el mismo nombre en esta compañía. Por favor cambia el nombre por uno diferente.'



    },
    experiences: {
        infoImage: 'Arrastra una imagen en el cuadro para poder visualizarla.',
        infoExpInput: 'Nombre de la experiencia.',
        infoCampaign: 'Toda experiencia debe estar dentro de un proyecto. Para crear un nuevo proyecto simplemente escribe su nombre; para agregar la experiencia a un proyecto existente escribe las primeras letras del proyecto y selecciónalo en el menú de sugerencias.',
        addTarget: 'Agregar Target',
        nameExp: 'Nombre de Experiencia',
        addOrDragTarget: 'Agrega o arrastra el target',
        newAlias: 'mvar.camonapp.com/?a=',
        newName: 'Nuevo nombre',
        success: 'Exito',
        successBody: 'Cambio realizado con éxito',
        error: 'Error el alias ya existe',
        editBackground: 'Editar fondo',
        changeBackground: 'Cambiar color de fondo',
        color: 'Color',
        image: 'Imagen',
        actions: {
            seeTarget: 'Ver Target',
            changeTarget: 'Cambiar Target',
            edit: 'Editar nombre de la experiencia',
            active: 'Activar Experiencia',
            deactive: 'Desactivar Experiencia',
            cloneExp: 'Clonar Experiencia',
            migExp: 'Migrar Experiencia',
            migExpAdmin: 'Migrar experiencia a otro usuario',
            removeExp: 'Eliminar Experiencia',
            open: 'Abrir',
            toEdit: 'Editar',
            seeReports: 'Ver Reportes',
            watchQR: 'Ver Codigo QR',
            changeURL: 'Estás por cambiar la URL de la experiencia.',
            endDate: 'Estás por programar el cambio de URL de la experiencia para ',
            changeAlias: 'Cambiar alias',
            changeName: 'Cambiar nombre',
        },
        msgActions: {
            changeTarget: 'Target modificado',
            clone: 'Experiencia clonada',
            activate: 'Experiencia activada',
            deactivate: 'Experiencia desactivada',
            migrate: 'Experiencia migrada'
        },
        QRDialog: {
            QRDialogTitle: 'Codigo QR',
            published: 'Esta experiencia se encuentra publicada',
            notPublished: 'Esta experiencia no ha sido publicada',
            tooltipMessage: 'Copiado!',
            copyToClipboard: 'Copiar en el portapapeles'

        },
        changeTarget: {
            validFormat: 'Tienes que agregar una imagen con formato válido (.jpg)',
            validEmptyTarget: 'Es necesario agregar un target',
            invalidSize: 'El target debe de pesar hasta 5 MB',
        },
        deleteExp: {
            msg: '¿Seguro deseas eliminar la experiencia?'
        },
        newExp: {
            imgTracking: 'Image Tracking',
            chooseTypeExp: 'Elige un tipo de experiencia',
            createExp: 'Crear experiencia',
            expTypePlane: 'experiencias de tipo plana',
            expTypeImageTracking: 'experiencias de tipo image tracking',
            createExpPlane: 'Crear una experiencia plana',
            createExpImageTracking: 'Image Tracking',

            expTypeWorldTracking: 'experiencias de tipo world tracking',
            createExpWorldTracking: 'World Tracking',

            expTypeImmersive: 'experiencias de tipo 360 Web',
            createExpImmersive: 'Web 360',

            expTypeWorld: 'experiencias de tipo mundo',
            activeExp: 'que activará tu experiencia',
            formatsTarget: 'Formatos de target .jpg',
            previewText: {
                title: 'Consejos para crear un soporte impreso con buena calidad de detección:',
                description: ' Variedad de formas y contrastes, Diseños con formas angulares que generan vértices, figuras y fondos bien definidos, contenidos gráficos en la mayoría de los superficies de la composición.',
                footer: 'Para más información sobre los targets visitá nuestra',
                support: 'Página de soporte'
            },
            valids: {
                requiredName: 'Necesitas agregar un nombre de experiencia',
                requiredProject: 'Necesitas seleccionar un proyecto',
                requiredTarget: 'Necesitas seleccionar un target',
                ExperienceAllreadyExists: 'Ya existe una experiencia con el mismo nombre en este proyecto. Por favor cambia el nombre por uno diferente.'
            }
        }
    },
    pages: {
        signUp: {
            errors: {
                confirmPassReq: 'Confirmar Contraseña requerida',
                emailReq: 'Email requerido',
                passwordReq: 'Contraseña requerida',
                passwordLength: "La contraseña no es lo suficientemente larga",
                passwordNotMatch: 'Las contraseñas no coinciden',
                accountExist: 'Ya existe una cuenta con esta dirección de email',
                characters: 'La contraseña debe tener al menos un número y un símbolo ',
                passwordMayus: 'La contraseña debe tener algún caracter con mayúscula',
                notEmpty: 'Los campos no pueden estar vacios',
                passwordMinus: 'La contraseña debe tener algún caracter con minúscula',
                passwordSymbol: 'La contraseña debe tener algún caracter de símbolo',
                someValueNull: 'Debe rellenar todos los campos',
                currentPasswordInvalid: 'La contraseña actual es incorrecta.',
                limitReached: 'Ha exedido el Límite de intentos, intente más tarde.'
            },
            registerInStudio: 'Registrate en Studio gratis y comenzá a crear experiencias de realidad aumentada',
            haveAnAccount: '¿Ya tenés cuenta?',
            enter: 'Ingresá',
            conditions: 'Al crear una cuenta en CamOn Studio acepto los ',
            conditionsPayment: 'Al comprar una cuenta en CamOn Studio acepto los ',
            conditionsTwo: 'Términos y Condiciones',
            conditionsAnd: 'y',
            politicies: 'Políticas de privacidad',
            createFreeAccount: 'Creá tu cuenta gratuita'
        },
        signIn: {
            errors: {
                userNotFound: 'Usuario no encontrado',
                notAuthorized: 'Email o contraseña incorrecta',
                notConfirmed: "Usuario no confirmado"
            },
            resetPass: 'Para poder ingresar a CamOn Studio será necesario que cambies tu contraseña',
            isNewUser: '¿Usuario nuevo?',
            createFreeAccount: 'Creá tu cuenta gratis',
            forgotMyPass: 'Olvidé mi contraseña',
            enterStudio: 'Ingresar a Studio',
            enter: 'Ingresá a'
        },
        campaigns: {
            modal: {
                edit: {
                    title: 'Editar el nombre del proyecto'
                },
                delete: {
                    title: 'Eliminar Proyecto',
                    msg: '¿Está seguro de eliminar el proyecto?'
                }
            },
            openProject: 'Abrir Proyecto',
            experiences: 'Experiencias',
            experience: 'Experiencia',
            namePlaceHolder: 'Nombre',
            actions: {
                changeName: 'Cambiar Nombre',
                delete: 'Eliminar',
                editCustomLoader: 'Editar custom loader',
                viewReport: 'Ver Reportes',
                migProject: 'Migrar Proyecto'
            },
            project: 'Proyectos'
        },
        campaign: {
            tutorial: {
                title: 'Aprendé a usar Studio',
                subtitle: 'Mirá nuestros tutoriales y aprendé todas las funcionalidades de CamOn Studio'
            },
            projectBreadcrumb: 'Proyecto'
        },
        profile: {
            deleteAccount: {
                dialog: '¿Seguro que deseas realizar esta acción?, por seguridad rellena el campo inferior con tu mail. ',
                inputLabel: 'correo',
            }
        },
        subscription: {
            form: {
                title: 'Envianos tus datos y nos pondremos en contacto',
                paymentMethod: 'Método de pago',
                discountCoupon: 'Cupón de descuento',
                promotions: 'Promociones',
                insertDiscount: 'Inserta tu cupón de descuento aquí',
                insertUsername: 'Ingresa tu nombre',
                insertSurname: 'Ingresa tu apellido',
                insertPhone: 'Ingresa un teléfono de contacto',
                insertBillingAddress: 'Ingresa tu domicilio',
                insertCP: 'Ingresar CP',
                insertCountry: 'Selecciona el país donde te encuentras',
                insertCity: 'Ingresa la ciudad o localidad',
                errorCoupon: 'El cupón no existe',
                fieldReq: 'Complete el campo',
                emailReq: 'Email es requerido',
                validEmail: "Por favor ingresar un email válido",
                reqTelephone: 'Favor de completar el campo de teléfono',
                validTelephone: 'El campo Teléfono solo puede tener valores numéricos',
                billing: 'Facturación',
                name: 'Nombre',
                surname: 'Apellido',
                cpCode: 'Codigo Postal',
                address: 'Dirección',
                city: 'Ciudad',
                country: 'País',
                personalData: 'Datos Personales',
                labels: {
                    coupon: 'Cupón',
                    username: 'Nombre',
                    surname: 'Apellido',
                    phone: 'Teléfono',
                    spanish: 'Español',
                    english: 'Inglés',
                    address: 'Dirección',
                    postalCode: 'Código Postal',
                    city: 'Ciudad/Localidad',
                    country: 'País',
                    submit: 'Enviar'
                },
                success: 'Nos pondremos en contacto a la brevedad.'
            },
            month: 'mes',
            year: 'año',
            title: 'Suscripción',
            basicPlan: 'Cuenta Básica',
            professionalPlan: 'Cuenta Profesional',
            canCancelSubscription: 'Puedes cancelar la suscripción cuando quieras',
            annualSaving: 'Ahorras 240 USD',
            upgradePlan: 'Actualizar a Pro',
            yearlyPlan: 'Plan anual',
            monthlyPlan: 'Plan mensual',
            withoutCreditCard: 'Acceso sin tarjeta de crédito',
            unlimitedExperiences: 'Crea experiencias ilimitadamente',
            publishDrafts: 'Publicar borradores',
            views: 'vistas',
            monthlyScan: 'escaneos mensuales',
            accessReportsMetrics: 'Acceso a reportes y métricas',
            helpCenter: 'Centro de ayuda',
            personalSupport: 'Soporte personalizado'
        },
        onboarding: {
            welcome: 'Bienvenido a Studio',
            firstExperience: 'Crea tu primera experiencia en CamOn Studio para conocer todas las funcionalidades y opciones de esta herramienta',
            buttonFirst: 'Crea tu primera experiencia',
        },
        confirm: {
            goToLogin: 'Ir a login',
            confirmationSend: {
                mailConfirmSent: 'Se ha enviado un email de confirmación',
                checkMail: 'Revisa tu mail y confirma tu cuenta',
            },
            resetSend: {
                mailSent: 'Te hemos enviado un email',
                checkMail: 'Revisa tu email y sigue las instrucciones para resetear tu contraseña'
            },
            resetOk: {
                passReset: 'Has restablecido tu contraseña correctamente'
            }
        },
        endOfTrial: {
            endTrial: 'Tu período de prueba terminó',
            paragraph: 'Esperamos que hayas disfrutado usando Studio hasta ahora, si quieres seguir usándolo de aquí en adelante actualiza tu cuenta a Pro. Recuerda que puedes dar de baja tu suscripción en el momento que quieras y que tus proyectos estarán para vos cuando decidas actualizar tu cuenta',
            upgrade: 'Actualiza a PRO'
        },
        error: {
            404: 'No pudimos encontrar la página que buscas',
            return: 'Volver a tus experiencias recientes'
        },
        account: {
            tabs: {
                subscription: 'Subscripción',
                companies: 'Compañías',
                users: 'Usuarios',
                publishes: 'Publicaciones',
                educationals: 'Entidades Educativas',
                professors: 'Profesores',
                students: 'Estudiantes',
                profile: 'Perfil'
            },
            deleteAccount: 'Error en la validación',
            backToProject: 'Volver a Proyectos',
            sureMsg: '¿Estás seguro?',
            pagination: {
                prev: 'Anterior',
                next: 'Siguiente'
            },
            addMsg: 'Se creó correctamente',
            classroom: {
                modal: {
                    add: {
                        title: 'Agregar Cátedra'
                    },
                    edit: {
                        title: 'Editar Cátedra'
                    }
                },
                headTable: {
                    name: 'Nombre',
                    code: 'Código',
                    professor: 'Profesor',
                    emailProfessor: 'Email Profesor',
                    actions: 'Acciones'
                },
                pagination: {
                    prev: 'Anterior',
                    next: 'Siguiente'
                },
                form: {
                    nameClassroom: 'Nombre de Cátedra',
                    nameProfessor: 'Nombre Profesor',
                    surnameProfessor: 'Apellido Profesor',
                    emailProfessor: 'Email Professor'
                }
            },
            companies: {
                modal: {
                    add: {
                        title: 'Agregar Compañía'
                    },
                    edit: {
                        title: 'Editar Compañía'
                    },
                    delete: {
                        title: 'Eliminar Compañía',
                        msg: '¿Estás seguro que deseas eliminar la compañía?'
                    }
                },
                headTable: {
                    name: 'Nombre',
                    type: 'Tipo',
                    canPublish: 'Puede Publicar',
                    reports: 'Puede Ver Reportes',
                    actions: 'Acciones'
                },
                pagination: {
                    prev: 'Anterior',
                    next: 'Siguiente'
                },
                form: {
                    credentials: 'Credenciales'
                }
            },
            educational: {
                addEntity: 'Agregar Entidad',
                modal: {
                    add: {
                        msg: '',
                        title: 'Agregar Entidad Educativa'
                    },
                    edit: {
                        title: 'Editar Entidad Educativa'
                    },
                    delete: {
                        title: 'Eliminar Entidad Educativa',
                        msg: '¿Estás seguro que deseas eliminar la institución educativa?'
                    }
                },
                headTable: {
                    name: 'Nombre',
                    type: 'Tipo',
                    canPublish: 'Puede Publicar',
                    reports: 'Ver Reportes',
                    actions: 'Acciones'
                },
                pagination: {
                    prev: 'Anterior',
                    next: 'Siguiente'
                },
                form: {
                    entityEducational: 'Entidad Educacional',

                }
            },
            professor: {
                modal: {
                    add: {
                        title: 'Agregar Profesor'
                    },
                    edit: {
                        title: 'Editar Profesor'
                    },
                    delete: {
                        title: 'Eliminar Profesor',
                        msg: 'Esta acción eliminará todos los contenidos relacionados.',
                        continue: '¿Deseas continuar?'
                    },
                }
            },
            publishes: {
                notification: {
                    rejected: 'Rechazado',
                    msgRejected: 'Se ha rechazado correctamente',
                    approved: 'Aprobado',
                    msgApproved: 'Se aprobó correctamente'
                },
                modal: {
                    approve: {
                        title: 'Aprobar Publicación',
                        msg: 'Se aprobará la publicación'
                    },
                    rejected: {
                        title: 'Rechazar Publicación',
                        msg: 'Se rechazará la publicación.'
                    }
                },
                headTable: {
                    campaign: 'Campaña',
                    name: 'Nombre',
                    out: 'Salida',
                    date: 'Fecha',
                },
                actions: {
                    see: 'Ver',
                    approve: 'Aprovar',
                    rejected: 'Rechazar'
                }
            },
            student: {
                modal: {
                    add: {
                        title: 'Agregar Estudiante',
                    },
                    edit: {
                        title: 'Editar Estudiante',
                    },
                    delete: {
                        title: 'Eliminar Estudiante',
                        msg: 'Esta acción eliminará todos los contenidos relacionados.'
                    }
                },
                headTable: {
                    classroom: 'Cátedra',
                },
            },
            subscription: {
                modal: {
                    cancel: {
                        title: '¿Deseas cancelar la Suscripción?'
                    }
                },
                labels: {
                    free: 'Gratis',
                    expiration: 'Tu prueba gratis finaliza en',
                    expired: 'Tu prueba gratis finalizó',
                    days: 'días',
                    freePlan: 'Actualmente te encuentras en plan gratuito',
                    noPurchase: 'No haz realizado ninguna compra aún',
                    yourPlan: 'Tu plan',
                    profesionalSubscription: 'Suscripción Profesional',
                    untilValid: 'Válida hasta',
                    planAnnual: 'Suscripción Anual',
                    planMonthly: 'Suscripción Mensual',
                    cancelButton: 'Cancelar Suscripción',
                    paymentMethod: 'Método de pago',
                    creditCard: 'Tarjeta de crédito',
                    billingInfo: 'Información de facturación',
                    nameSurname: 'Nombre y Apellido',
                    address: 'Dirección',
                    city: 'Ciudad',
                    country: 'País',
                    invoices: 'Facturas',
                    downloadInvoice: 'Descargar Factura',
                    subscriptionCancelled: 'Se canceló la suscripción correctamente',
                    subscription: 'Subscripción'
                }
            },
            userProfile: {
                modal: {
                    edit: {
                        title: 'Editar información de la cuenta'
                    },
                    sec: {
                        title: 'Editar información de seguridad'
                    },
                    delete: {
                        title: 'Eliminar cuenta'
                    },
                    changePass: {
                        title: 'Alerta',
                        msg: 'Se procederá a cerrar la sesion debido al cambio de contraseña'
                    }
                },
                labels: {
                    fakeName: 'Nombre',
                    user: 'USUARIO',
                    phone: 'Teléfono',
                    spanish: 'Español',
                    english: 'Inglés',
                    language: 'Idioma',
                    security: 'Seguridad',
                    password: 'Contraseña',
                    inviteLink: 'Enlace de invitación',
                    link: 'Link',
                    deleteAccount: 'Eliminar cuenta',
                    deleteAccountStudio: 'Eliminar mi cuenta de CamOn Studio',
                    phoneInvalid: 'Numero de telefono invalido',
                    previousPassword: 'Contraseña anterior',
                    newPassword: 'Contraseña nueva',
                    confirmPassword: 'Confirmar contraseña',
                }
            },
            users: {
                modal: {
                    add: {
                        msg: 'Se creó correctamente',
                        title: 'Agregar Usuario'
                    },
                    update: {
                        msg: 'Se actualizó correctamente',
                        title: 'Editar Usuario'
                    },
                    disable: {
                        msg: 'Esta acción despublicará todos los contenidos relacionados.',
                        title: 'Deshabilitar Usuario'
                    },
                    delete: {
                        title: 'Eliminar Usuario',
                        msg: 'Esta acción eliminará todos los contenidos relacionados.'
                    },
                    edit: {
                        title: 'Editar Usuario'
                    }
                },
                form: {
                    labelUserType: 'Tipo de usuario',
                    userType: {
                        userCamonapp: 'CamOn Editor',
                        marketing: 'Marketing',
                        admin: 'Administrador',
                        companyUser: 'Usuario de compañía'
                    },
                    company: 'Compañía'
                }
            }
        },
        recent: {
            recentExp: 'Experiencias Recientes'
        },
        experiencesT: 'Experiencias',
        reports: {
            reports: 'Reportes',
            tabs: {
                allCompanies: 'Todas las empresas',
                campaign: 'CAMPAÑA',
                experience: 'EXPERIENCIA'
            },
            alertTitle: 'Generando Reporte',
            alertBody: 'El reporte está siendo generado y será enviado a su mail en los próximos minutos',
            headTable: {
                company: 'Empresa',
                filter: 'Filtrar',
                views: 'Vistas',
                campaign: 'Campaña',
                experience: 'Experiencia',
                actionType: 'Tipo de acción',
                quantity: 'Cantidad'
            },
            totalViews: 'Vistas Totales',
            users: 'Usuarios',
            time: 'Tiempo promedio',
            downloadReport: 'Descargar Reporte',
            viewForDay: 'Número de vistas por día',
            statistics: 'Estadísticas',
            cantView: 'Cantidad de vistas - fechas de interacción',
            demographicData: 'Datos demográficos',
            so: 'Sistema Operativo'
        },
        webComponents: {
            webComponents: "Overlays"
        },
        webComponents: {
            webComponents: "Componentes Web"
        },
        customForgot: {
            paragraph: 'Ingresá tu email con el que te registraste y te enviaremos las instrucciones para recuperar la contraseña',
            recoveryPass: 'Recuperá tu contraseña',
            resetPass: 'Reseteá tu contraseña',
            backToLoginButton: 'Ok, volver a iniciar sesión',
            infoMsg: 'Revisa la cuenta, recibiras un email con las intrucciones de como reestablecer tu contraseña. Recordá revisar la carpeta de correo no deseado',
            titleMsg: 'Te hemos enviado un mail'
        },
        requireNewPass: {
            passwordReset: 'Contraseña restablecida',
            updatePassword: 'Actualizar contraseña',
            titleMsg: 'Has reestablecido tu contraseña correctamente'
        },
        status: {
            statusExp: 'Estado de tus experiencias',
            seeAll: 'Ver todos'
        }
    },
    notification: {
        action: {
            expCreated: "Experiencia creada con Éxito"
        },
        label: "Cerrar"
    },
    filters: {
        labels: {
            status: 'Estado',
            experienceType: 'Tipo de experiencia',
            order: 'Ordenar por',
        },
        status: {
            all: 'Todos',
            published: 'Publicados',
            notPublished: 'No publicados',
            onReview: 'Revisión',
            rejected: 'Rechazados'
        },
        expType: {
            all: 'Todos',
            world: 'Mundo',
            plane: 'Plana'
        },
        sort: {
            recent: 'Reciente',
            created: 'Fecha de creación',
            name: 'Nombre'
        },
        project: 'Proyectos'
    },
    help: {
        goToSupport: 'Ir a soporte',
        termsAndConditions: 'Terminos y condiciones',
        privacyPolicies: 'Políticas de privacidad'
    },
    assets: {
        error: {
          maxVertices: "Excede los 75000 polígonos",
          maxResolutionGlb: "Excede el máximo de resolución permitido para texturas: 2048x2048",
        }
    },
    overlays:{
        title: "Overlays",
        selectComponenLabel: "Overlays",
        visualiser:{
            desktop: "Escritorio",
            mobile: "Celular"
        },
        propertyLabel: "Propiedad",
        properties:{

            select: "Variables",
            visibility: "Visibilidad",
            height:"Alto",
            width: "Ancho",
            size: "Tamaño",
            personalizationButton: "Personalización del botón",
            background:"Color de fondo",
            iconUrl:"Imagen",
            setUrl: "Url de imagen",
            primaryTextColor: "Color de texto primario",
            secondaryTextColor: "Color de texto secundario",
            primaryBackgroundColor: "Color de fondo",
            secondaryBackgroundColor: "Color de fondo secundario",
            primaryBackgroundUrl: "Url de imagen en reposo",
            secondaryBackgroundUrl: "Url de imagen al hacer click",
            terciaryBackgroundUrl:"Url de imagen al hacer click",
            shape:"Forma",
            type:"Tipo",
            top: "Distancia al margen superior",
            left: "Distancia al margen izquierdo",
            right: "Distancia al margen derecho",
            bottom: "Distancia al margen inferior",
            unity: "Unidad",
            url : "Ruta",
            steps:"Pasos",
            title:"Título",
            description:"Descripción",
            activeItemColor:"Color de stepper activo",
            itemColor: "Color de stepper pasivo",
            buttonColor:"Color del botón",
            font:"Fuente",
            timeToAppear: "Delay",
            textLeftButton: "Texto del boton izquierdo",
            textRightButton: "Texto del boton derecho",
            position:"Posición",
            header:"Título",
            transitionDirection:"Transición",
            transitionLTR: "De izquierda a derecha",
            transitionRTL: "De derecha a izquierda",
            leftPosition: "Izquierda",
            rightPosition: "Derecha",
            centerPosition:"Centro",
            tab: "Pestaña",
            new: "Nueva",
            current: "Actual",
            items:"Items del menú",
            href:"Url destino",
            textAlign: "Alineación del texto",
            text:"Texto",
            shape: "Forma",
            textAndColor: "Texto y color de botones",
            buttonsColor: "Color de botones",
            alignment: "Alineación",
            primaryText: "Texto primario",
            secondaryText: "Texto secundario",
            textColor: "Color del texto primario y secundario",
            textColorButtons: "Color del texto de botones",
            backgroundRightButton: "Color de fondo del botón derecho",
            backgroundLefttButton: "Color de fondo del botón Izquierdo",
            backgroundRightButtonHover: "Color de fondo del botón derecho en hover",
            backgroundLefttButtonHover: "Color de fondo del botón Izquierdo en hover",
            backgroundRightButtonClick: "Color de fondo del botón derecho en click",
            backgroundLefttButtonClick: "Color de fondo del botón Izquierdo en click",
            textColorLeftButton:"Color de texto del botón izquierdo",
            textColorRightButton:"Color de texto del botón derecho",
            textColorLeftButtonHover: "Color de texto del boton izquierdo en hover",
            textColorRightButtonHover: "Color de texto del boton derecho en hover",
            colors: "Colores",
            text: "Texto",
            closeButton: "Botón para cerrar",
            mesaures: "Medidas"
            
        },
        export:  "Exportar",
        includeLibrary: "Incluir librería",
        copied: "Copiado",
        copy: "Copiar",
        component : {
            cowcFooterBotton: {
                value: "cowc-footer-botton",
                label: "Pie de página"
            },
            cowc360NavigationTour:{
                value: "cowc-360-navigation-tour",
                label: "Tour de navegación"
            },
            cowcBackButton:{
                value: "cowc-back-button",
                label: "Botón de volver"
            },
            cowcIconButton: {
                value: "cowc-icon-button",
                label: "Botón con ícono"
            },
            cowcMenu:{
                value: "cowc-menu",
                label: "Menú"
            },
            cowcModal:{
                value: "cowc-modal",
                label: "Modal"
            },
            cowcModalWithTrigger:{
                value: "cowc-modal-with-trigger",
                label: "Modal con botón"
            },
            cowcShopButton:{
                value: "cowc-shop-button",
                label: "Botón de compra"
            }
        },
    },
        
    tablePublications: {
        title: 'Panel de control',
        experience: 'Experiencia',
        user: 'Usuario',
        limitDate: 'Fecha fin',
        timer: 'Tiempo',
        status: 'Off / On'
    },
    dateModal:{
        msjError: "Por favor, elija una fecha futura"
    }
}