import jwt from './jwt';
import refresh from './refresh';

export default function setCookie() {
    refresh()
        .then((token) => {
            document.cookie = `refresh_token =; domain = ${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
            document.cookie = `refresh_token=${token}; domain=${process.env.REACT_APP_APP_DOMAIN};`;
        })

}

export function deleteCookie() {
    document.cookie = `refresh_token=; domain=${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    document.cookie = `token=; domain=${process.env.REACT_APP_APP_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`
}
