import React, {useState, useEffect, useContext} from 'react';
import {TextField} from '@rmwc/textfield';
import {useForm} from "react-hook-form";
import {Button} from '@rmwc/button';
import {useTranslate} from 'react-polyglot';

import Icon from '../icon';
import DropInput from '../dropInput';
import CampaignService from '../../services/Campaign';
import {createExperience} from '../../utils/experienceActions';
import ErrorMessage from '../ErrorMessage/ErrorMessage'

import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@rmwc/icon/icon.css';
import AppContext from '../app/AppContext';
import {ReactSVG} from 'react-svg';
import imageLogo from './imgIcon.jpg'

function NewExperience(props) {
    const back = props.back;
    const t = useTranslate();

    const [experienceRoad, setExperienceRoad] = useState({experience_type: ''});
    const [hover, setHover] = useState({experience_type: ''});

    const { register, handleSubmit } = useForm();
    const context = useContext(AppContext);
    const [step, setStep] = useState(1);
    const [type, setType] = useState('');
    const [project, setProject] = useState(null);
    const [name, setName] = useState("");
    const [inputFileValue, setInputFileValue] = useState("");

    const [projects, setProjects] = useState([]);

    const [fileValue, setFileValue] = useState('');
    const [filePath, setFilePath] = useState('');
    const [, setPlainHover] = useState(false);
    const [, setWorldHover] = useState(false);
    const [campaign, setCampaign] = useState({});

    //handler state for validation of the experience form
    const [dataError, setDataError] = useState(false);
    const [validDataMessage, setValidDataMessage] = useState('');
    const validData = (data) => {
        let valid = data.name.trim() === '' || data.campaign.name.trim() === '' || (data.target.length === 0 && data.type === 'plain');
        if (data.name.trim() === "") {
            setValidDataMessage('experiences.newExp.valids.requiredName')
        } else if (data.campaign.name.trim() === "") {
            setValidDataMessage('experiences.newExp.valids.requiredProject')
        } else if (data.target.length === 0 && data.type === 'plain') {
            setValidDataMessage('experiences.newExp.valids.requiredTarget')
        }
        setDataError(valid)
        return !valid
    };

    useEffect(() => {
        CampaignService.listCampaings()
            .then((res) => {
                setProjects(res);
            });
    }, [])
    useEffect(() => {

        if (step === 2) {
            setStep(1)
            props.onChangeStep(1)
        }
    }, [back])

    function getFilePath(file) {
        let validFileTypes = ['JPEG', 'jpeg', 'jpg', 'JPG'];
        let fileExtension = file.files[0] ? file.files[0].name.split('.').pop() : '';
        window.URL = window.URL || window.webkitURL;
        if (file.files[0] && validFileTypes.includes(fileExtension)) {

            const url = URL.createObjectURL(file.files[0]);
            const size = file.files[0].size;
            if (Math.round(((size / 1024) / 1024)) >= 10) {
                // TODO: Api Gateway no soporta archivos mayores a 10mb validar que hacer con eso
            }
            setDataError(false);
            setValidDataMessage('');
            setFilePath(url);
        } else {
            if (!validFileTypes.includes(fileExtension)) {
                setDataError(true);
                setValidDataMessage(t('experiences.changeTarget.validFormat'))
            }
            setFileValue(false);
        }

    }

    function checkInput(e) {

    }

    function getCampaign(campaign) {

        setCampaign(campaign);
    }

    function triggerFile(e) {
        e.preventDefault();
        document.getElementById('inputFile').click();

    }

    const handleChange = set => evt => {
        set(evt.target.value);
    };

    function resetDataAndDissmiss() {
        setStep(1);
        setType('');
        setProject(null);
        setName('');
        setDataError(false);
        setValidDataMessage("");
    }

    function onSubmit(data) {
        if (validData(data)) {
            context.setPage({...context.page, loading: true});
            createExperience(data)
                .then(function (res) {
                    context.setPage({...context.page, loading: false});
                    if (context.page.refetch) {
                        context.page.refetch()
                    }
                    props.onFinish(true);
                    window.open(`${process.env.REACT_APP_EDITOR_DOMAIN}?id=${res.id}`, "_blank");
                })
                .catch(function (err) {
                    context.setPage({...context.page, loading: false});
                    props.onFinish();
                    resetDataAndDissmiss();
                })
        }
    }

    return (
        <section>
            <div className={'new-experience-title-container'}>
                {step === 1 ?
                    <h2 className={'new-experience-title'}>{t('experiences.newExp.chooseTypeExp')}</h2>
                    :
                    <h2 className={'new-experience-title'}>
                        {t('experiences.newExp.createExp')}
                    </h2>
                }
            </div>
            {step === 1 ?
                <div className={'new-experience-type-container'}>

                    <div className={'new-experience-type'} onMouseEnter={() => {
                        setHover({experience_type: 'image_tracking'});
                        setPlainHover(true)
                    }} onMouseLeave={() => {
                        setHover({experience_type: ''});
                        setPlainHover(false)
                    }} onClick={() => {
                        setExperienceRoad({...experienceRoad, ...{experience_type: '3'}});
                        setStep(3);
                        setType('image_tracking');
                        if (props.onChangeStep) {
                            props.onChangeStep()
                        }
                    }}>
                        <div className={'new-experience-type-header'}>
                            <div className={'placeholder'}>
                                {hover.experience_type === 'image_tracking' ?
                                    <ReactSVG src="/images/illustration-plain-hover.svg"/>
                                    :
                                    <ReactSVG src="/images/illustration-plain.svg"/>
                                }
                            </div>
                        </div>
                        <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'image_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('common.labels.imageTracking')}</span>
                        </div>
                        <div className={'new-experience-type-text'}>
                            <p>
                                {t('experiences.newExp.expTypeImageTracking')}
                            </p>
                        </div>
                        <div className={'new-experience-type-button'}>
                            <Button label={t('experiences.newExp.createExpImageTracking')}
                                    className={'new-experience-button'}
                                    outlined={!hover.experience_type}
                                    raised={hover.experience_type === 'image_tracking'}/>
                        </div>
                    </div>
                    <div className={'new-experience-type'} onMouseEnter={() => {
                        setHover({experience_type: 'world_tracking'});
                        setPlainHover(true)
                    }} onMouseLeave={() => {
                        setHover({experience_type: ''});
                        setPlainHover(false)
                    }} onClick={() => {
                        setExperienceRoad({...experienceRoad, ...{experience_type: '2'}});
                        setStep(2);
                        setType('plain');
                        if (props.onChangeStep) {
                            props.onChangeStep()
                        }
                    }}>
                        <div className={'new-experience-type-header'}>
                            <div className={'placeholder'}>
                                {hover.experience_type === 'world_tracking' ?
                                    <ReactSVG src="/images/illustration-plain-hover.svg"/>
                                    :
                                    <ReactSVG src="/images/illustration-plain.svg"/>
                                }
                            </div>
                        </div>
                        <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'world_tracking' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('common.labels.worldTracking')}</span>
                        </div>
                        <div className={'new-experience-type-text'}>
                            <p>
                                {t('experiences.newExp.expTypeWorldTracking')}
                            </p>
                        </div>
                        <div className={'new-experience-type-button'}>
                            <Button label={t('experiences.newExp.createExpWorldTracking')}
                                    className={'new-experience-button'}
                                    outlined={!hover.experience_type}
                                    raised={hover.experience_type === 'world_tracking'}/>
                        </div>
                    </div>
                    <div className={'new-experience-type'} onMouseEnter={() => {
                        setHover({experience_type: 'immersive'});
                        setWorldHover(true)
                    }} onMouseLeave={() => {
                        setHover({experience_type: ''});
                        setWorldHover(false)
                    }} onClick={() => {
                        setExperienceRoad({...experienceRoad, ...{experience_type: '1'}});
                        setStep(2);
                        setType('world');
                        if (props.onChangeStep) {
                            props.onChangeStep()
                        }
                    }}>
                        <div className={'new-experience-type-header'}>
                            <div className={'placeholder'}>
                                {hover.experience_type === 'immersive' ?
                                    <ReactSVG src="/images/illustration-world-hover.svg"/>
                                    :
                                    <ReactSVG src="/images/illustration-world.svg"/>
                                }

                            </div>
                        </div>
                        <div className={'new-experience-type-title'}>
                            <span
                                className={`${hover.experience_type === 'immersive' ? 'hoverTitle' : 'noHoverTitle'}`}>{t('common.labels.immersive')}</span>
                        </div>
                        <div className={'new-experience-type-text'}>
                            <p>
                                {t('experiences.newExp.expTypeImmersive')}
                            </p>
                        </div>
                        <div className={'new-experience-type-button'}>
                            <Button label={t('experiences.newExp.createExpImmersive')}
                                    className={'new-experience-button'}
                                    outlined={!hover.experience_type}
                                    raised={hover.experience_type === 'immersive'}/>
                        </div>
                    </div>
                </div>
                :
                <div className={'new-experience-type-container'}>
                    <div className={'new-experience-form'}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="campaign[name]" value={campaign.name || ''} ref={register}/>
                            <input type="hidden" name="campaign[id]" value={campaign.id || ''} ref={register}/>
                            <input type="hidden" name="type" value={type || ''} ref={register}/>
                            <div className={'form-control'}>
                                <TextField name={'name'} value={name} outlined label="Nombre de la experiencia" required
                                           onChange={handleChange(setName)} inputRef={register}/>
                            </div>
                            <div className={'form-control file-container'}>
                                <DropInput
                                    label="Crear un proyecto o agrega la experiencia a un proyecto existente"
                                    outlined
                                    className={'new-experience-file-input'}
                                    icon={<Icon name={'campaings'} className={'new-experience-file-input-icon'}/>}
                                    onChangeFn={getCampaign}
                                    data={projects}
                                    project={project}
                                />

                                {//<span className={'file-input-value'}>{fileValue.split('\\').reverse()[0]}</span>
                                }
                            </div>
                            {
                                dataError ? <div><ErrorMessage message={validDataMessage}/></div> : null
                            }
                            <div className={'form-control preview-file'}>
                                <div className={'new-experience-preview' + (fileValue ? ' file-selected' : '')}
                                     onClick={() => {
                                         if (fileValue) {
                                             document.getElementById('inputFile').click();
                                         }
                                     }}>
                                    <input id="inputFile" type="file" name="target" style={{display: 'none'}}
                                           defaultValue={inputFileValue} onChange={(e) => {
                                        setInputFileValue(e.target.value);
                                        setFileValue(true);
                                        getFilePath(e.target);
                                        checkInput(e)
                                    }} ref={register}/>

                                    {fileValue ?
                                        <img src={filePath} alt={'fileValue'}
                                             className={'new-experience-preview-image'}/>
                                        :
                                        <div className={'image-input'}>
                                            <div className={'label-input-image'}>
                                                <span>
                                                    {t('experiences.addOrDragTarget')}
                                                    <img src={imageLogo} alt=""/>
                                                    {t('experiences.newExp.activeExp')}
                                                </span>
                                            </div>
                                            <div className={'label-secondary'}>
                                                <span>{t('experiences.newExp.formatsTarget')}</span>
                                            </div>
                                            <div>
                                                <Button outlined label={t('experiences.addTarget')}
                                                        className={'new-experience-button'} onClick={triggerFile}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={'new-experience-preview-text'}>
                                    <p className={'title'}>{t('experiences.newExp.previewText.title')}</p>
                                    <p className={'description'}>{t('experiences.newExp.previewText.description')}</p>
                                    <p className={'footer'}>
                                        {t('experiences.newExp.previewText.footer')} <span
                                        className="link">{t('experiences.newExp.previewText.support')}</span>
                                    </p>
                                </div>
                            </div>
                            <div className={'form-control'}>
                                <Button type="submit" label={t('actions.accept')} raised
                                        className={'login-button experience-button'}/>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </section>
    )
}

export default NewExperience;