import React from 'react';
import {Auth} from 'aws-amplify';
import {navigate} from "@reach/router"
import _ from 'lodash';

import Header from './Header';
import FloatingButton from './FloatingButton';
import {Layout, Fixed, Flex} from 'react-layout-pane';
import Menu from '../menu';
import Filters from '../Filters';
import DialogContainer from '../dialog';
import Loading from '../loading';
import SubsDialog from '../actionsDialog/subsDialog'
import AppContext from '../app/AppContext';
import Onboarding from '../../pages/onBoarding/Onboarding';
import EndOfTrial from '../../pages/EndofTrial/EndOfTrial';
import campaignService from '../../services/Campaign';
import { isExpiredUser } from '../../utils/user';
import { userGroupsObject } from '../../utils/userGroups';
import CheckPermision from '../checkPermision/checkPermision';

import './Layout.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

class LayoutBase extends React.Component {
    state = {
        collapsible: false,
        showLoading: false,
        onBoarding: false,
        endOfTrial: false,
        OpenSnack: false,
        ready: false,
        notification: null
    }

    constructor(props) {
        super(props);
        this.handleCollapse = this.handleCollapse.bind(this);
    }

    openOnBoarding() {
        navigate('/onboarding')
    }

    async componentWillMount() {
        const campaignsResponse = await campaignService.listWithExperiences();
        if (campaignsResponse.data.length === 0 && !this.checkExpiredUser()) {
            this.openOnBoarding();
        } else {
            this.setState({ready: true});
        }
    }

    componentDidUpdate() {
        const showLoading = this.context ? this.context.page.loading : false;
        const showOnBoarding = this.context ? this.context.page.onBoarding : false;
        const showEndofTrial = this.context ? this.context.page.ondOfTrial : false;
        const showNotification = this.context?.page?.notification ? Object.keys(this.context.page.notification).length : 0;


        if (showLoading) {

            this.enableLoading();
        } else {
            this.disableLoading();
        }
        if (showOnBoarding) {
            this.enableonBoarding();
        } else {
            this.disableonBoarding();
        }
        if (showEndofTrial) {
            this.enableEndOfTrial();
        }

        if (CheckPermision.check(this.context.userGroups, [userGroupsObject.FreelanceUsers])
            && !_.isEmpty(this.context.userData)
            && window.location.pathname !== '/account/subscription'
            && window.location.pathname !== '/account/profile'
            && isExpiredUser(this.context.userData)) {
            this.goToSubscription();
        }
    }

    checkExpiredUser() {
        return CheckPermision.check(this.context.userGroups, [userGroupsObject.FreelanceUsers])
        && !_.isEmpty(this.context.userData)
        && isExpiredUser(this.context.userData)
    }

    enableEndOfTrial() {
        this.setState({
            endOfTrial: true
        })
    }

    enableonBoarding() {
        if (!this.state.onBoarding) {
            this.setState({
                onBoarding: true
            });
        }
    }

    enableSnack() {
        if (!this.state.OpenSnack) {
            this.setState({
                OpenSnack: true
            });
        }
    }

    disableSnack() {
        if (this.state.OpenSnack) {
            this.setState({
                OpenSnack: false
            });
        }
    }

    disableonBoarding() {
        if (this.state.onBoarding) {
            this.setState({
                onBoarding: false
            });
        }
    }

    enableLoading() {
        if (!this.state.showLoading) {
            this.setState({
                showLoading: true
            });
        }
    }

    disableLoading() {
        if (this.state.showLoading) {

            this.setState({
                showLoading: false
            });
        }
    }

    handleCollapse(collapse) {
        this.setState(
            {
                collapsible: collapse
            }
        );

    }

    async logout() {
        await Auth.signOut();
        window.location.href = '/';
    }

    goToSubscription() {
        navigate('/account/subscription');
    }

    render() {
        if (!this.state.ready)
            return null;
        return (
            <Layout type="column">

                {this.state.showLoading &&
                <Loading show={true}/>
                }

                {this.state.onBoarding &&
                <Onboarding/>
                }
                {this.state.endOfTrial &&
                <EndOfTrial/>
                }


                <Fixed className="header">

                    <Header hideSearch={this.context.page.shortLayout}/>


                </Fixed>
                <Flex>
                    <Layout type="row">

                        {!this.context.page.shortLayout &&
                        <Fixed className="sidebar"> <Menu onCollapse={this.handleCollapse}/></Fixed>
                        }
                        <Flex
                            className={this.state.collapsible ? `content collapsed ${this.context.page.shortLayout ? 'content-full' : ''}` : `content ${this.context.page.shortLayout ? 'content-full' : ''}`}>
                            <div>
                                <DialogContainer/>

                                <SubsDialog/>
                                {!this.context.page.shortLayout &&
                                <Filters hideStatusFilter={this.context.page.hideStatusFilter} hideFilters={this.context.page.hideFilters} noRenderFilterContainer={this.context.page.noRenderFilterContainer}/>
                                }

                                {this.props.children}

                            </div>
                            <FloatingButton/>
                        </Flex>
                    </Layout>
                </Flex>
            </Layout>
        )
    }
}

LayoutBase.contextType = AppContext;
export default LayoutBase;
